import { environment } from '../../environments/environment';
import { getLabelFromKey, getKeyFromLabel } from './functions';

const APP_NAME = environment.APP_NAME;
const API_V1_END_POINT = environment.API_V1_END_POINT;

const DATE_FORMAT = {
  MM_DD_YYYY: 'MM-DD-YYYY',
};

const ROLES = {
  USER: 'user',
  AGENT: 'agent',
  ADMIN: 'admin',
};

const SERVICES = {
  LISTING: 'Listing',
  CROSSLISTING: 'Crosslisting',
  DELISTING: 'Delisting',
  STUFFBUYING: 'Stuffbuying',
  ACCOUNTING: 'Accounting',
  RELISTING: 'Relisting',
};

const PLATFORM = {
  EBAY: 'Ebay',
  POSHMARK: 'PoshMark',
  MERCARI: 'Mercari',
  DEPOP: 'Depop',
  ETSY: 'Etsy',
  TRADESE: 'TradeSe',
  OTHER: 'other',
};

// const USER_TYPES = [
//   { label: getLabelFromKey(ROLES.USER), value: ROLES.USER },
//   { label: getLabelFromKey(ROLES.AGENT), value: ROLES.AGENT },
//   { label: getLabelFromKey(ROLES.ADMIN), value: ROLES.ADMIN },
// ];

const KEYS = {
  API_TOKEN: 'api-token',
  USER_INFO: 'user-info',
  ROLE: 'role',
};

const SERVICES_LIST = [
  { label: SERVICES.LISTING, value: getKeyFromLabel(SERVICES.LISTING) },
  { label: SERVICES.CROSSLISTING, value: getKeyFromLabel(SERVICES.CROSSLISTING) },
  { label: SERVICES.DELISTING, value: getKeyFromLabel(SERVICES.DELISTING) },
  { label: SERVICES.STUFFBUYING, value: getKeyFromLabel(SERVICES.STUFFBUYING) },
  { label: SERVICES.ACCOUNTING, value: getKeyFromLabel(SERVICES.ACCOUNTING) },
  { label: SERVICES.RELISTING, value: getKeyFromLabel(SERVICES.RELISTING) },
];

const PLATFORM_LIST = [
  { label: PLATFORM.EBAY, value: getKeyFromLabel(PLATFORM.EBAY) },
  { label: PLATFORM.POSHMARK, value: getKeyFromLabel(PLATFORM.POSHMARK) },
  { label: PLATFORM.MERCARI, value: getKeyFromLabel(PLATFORM.MERCARI) },
  { label: PLATFORM.DEPOP, value: getKeyFromLabel(PLATFORM.DEPOP) },
  { label: PLATFORM.ETSY, value: getKeyFromLabel(PLATFORM.ETSY) },
  { label: PLATFORM.TRADESE, value: getKeyFromLabel(PLATFORM.TRADESE) },
];

const ITEM_TAB_TYPE = {
  NEW: {
    key: 'new',
    label: 'New',
  },
  SUBMITTED: {
    key: 'submitted',
    label: 'Submitted',
  },
  DRAFTED: {
    key: 'drafted',
    label: 'Drafted',
  },
  USER_CONCERN: {
    key: 'my-concern',
    label: 'My Concern',
  },
  AGENT_CONCERN: {
    key: 'agent-concern',
    label: 'Agent Concern',
  },
  LISTED: {
    key: 'listed',
    label: 'Listed',
  },
};

const ITEM_TAB_LIST = [
  {
    title: ITEM_TAB_TYPE.NEW.label,
    tabId: ITEM_TAB_TYPE.NEW.label,
    tag: 'item-list',
  },
  {
    title: ITEM_TAB_TYPE.SUBMITTED.label,
    tabId: ITEM_TAB_TYPE.SUBMITTED.label,
    tag: 'item-list',
  },
  {
    title: ITEM_TAB_TYPE.DRAFTED.label,
    tabId: ITEM_TAB_TYPE.DRAFTED.label,
    tag: 'item-list',
  },
  {
    title: ITEM_TAB_TYPE.USER_CONCERN.label,
    tabId: ITEM_TAB_TYPE.USER_CONCERN.label,
    tag: 'item-list',
  },
  {
    title: ITEM_TAB_TYPE.AGENT_CONCERN.label,
    tabId: ITEM_TAB_TYPE.AGENT_CONCERN.label,
    tag: 'item-list',
  },
  {
    title: ITEM_TAB_TYPE.LISTED.label,
    tabId: ITEM_TAB_TYPE.LISTED.label,
    tag: 'item-list',
  },
];

const INPUT_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  EMAIL: 'email',
  NUMBER: 'number',
  SEARCH: 'search',
  TELEPHONE: 'tel',
  URL: 'url',
  TEXTAREA: 'textarea',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  TOGGLE: 'toggle',
  CURRENCY: 'currency',
  AUTOCOMPLETE: 'autocomplete',
};

const INPUT_TYPE_VALIDATIONS = {
  REQUIRED: 'required',
  REQUIRED_TRUE: 'requiredTrue',
  MAX: 'max',
  MIN: 'min',
  EMAIL: 'email',
  MIN_LENGTH: 'minlength',
  MAX_LENGTH: 'maxlength',
  MIN_VALUE: 'min',
  MAX_VALUE: 'max',
  PATTERN: 'pattern',
  NULL_VALIDATOR: 'nullValidator',
};

const GENDERS = [
  { key: 'Male', label: 'Male' },
  { key: 'Female', label: 'Female' },
  { key: 'Unisex', label: 'Unisex' },
  { key: 'Kids', label: 'Kids' },
  { key: 'Boys', label: 'Boys' },
  { key: 'Girls', label: 'Girls' },
  { key: 'Others', label: 'Others' },
];

const CONDITIONS = [
  { value: 'New With Tags', key: 'New With Tags', label: 'New With Tags' },
  { value: 'New With Box', key: 'New With Box', label: 'New With Box' },
  { value: 'New Other', key: 'New Other', label: 'New Other' },
  { value: 'Used', key: 'Used', label: 'Used' },
  { value: 'Refurbished', key: 'Refurbished', label: 'Refurbished' },
  { value: 'Pre-owned', key: 'Pre-owned', label: 'Pre-owned' },
];

const USER_TYPES = {
  USER: {
    label: getLabelFromKey(ROLES.USER),
    value: ROLES.USER,
  },
  AGENT: {
    label: getLabelFromKey(ROLES.AGENT),
    value: ROLES.AGENT,
  },
  ADMIN: {
    label: getLabelFromKey(ROLES.ADMIN),
    value: ROLES.ADMIN,
  },
};

const PAGE_SIZE_LIST = [25, 50, 100, 200];
const DEFAULT_PAGE_SIZE = PAGE_SIZE_LIST[0];
const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_CURRENCY = 'USD';
const DEFAULT_CURRENCY_SYMBOL = '$';
const DEFAULT_SUBSCRIPTION_AMOUNT = 99;

const MENU_ITEM_KEY = {
  VIEW_EDIT: 'view-edit',
  DELETE: 'delete',
  USER_CONCERN: 'my-concern',
  AGENT_CONCERN: 'agent-concern',
};


export {
  APP_NAME,
  API_V1_END_POINT,
  DATE_FORMAT,
  USER_TYPES,
  ROLES,
  KEYS,
  SERVICES,
  SERVICES_LIST,
  PLATFORM,
  PLATFORM_LIST,
  ITEM_TAB_TYPE,
  ITEM_TAB_LIST,
  INPUT_TYPE,
  INPUT_TYPE_VALIDATIONS,
  GENDERS,
  CONDITIONS,
  PAGE_SIZE_LIST,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_CURRENCY,
  DEFAULT_CURRENCY_SYMBOL,
  DEFAULT_SUBSCRIPTION_AMOUNT,
  MENU_ITEM_KEY,
};

export const USER_STATUS = {
  REGISTER: 'Register',
  SUBSCRIBE: 'Subscribe',
  AUTO_SUBSCRIBE: 'AutoSubscribe',
  UNSUBSCRIBE: 'UnSubscribe',
  VERIFIED: 'Verified',
  UNVERIFIED: 'Unverified',
};

export const PLATFORM_IMAGE_MAPPER = {
  'ebay': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/eBay.png',
  'poshmark': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/Poshmark.png',
  'mercari': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/Mercari.png',
  'facebook_marketplace': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/Facebook.png',
  'depop': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/Depop.png',
  'tradsey': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/Tradsey.png',
  'etsy': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/Etsy.png',
  'amazon_seller_central': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/Amazon.png',
  'keepa': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/Keepa.png',
  'jungle_scout': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/Jungle+Scout.png',
  'laroi': 'https://kreate-logo-images.s3.us-west-2.amazonaws.com/LacRoi.png',
};
