import { Injectable } from '@angular/core';
import { UserService } from '..';
import { getApiEndPoint } from '../../../../constants';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  constructor(
    private httpService: HttpService,
    private userService: UserService) { }

  public getAgentList() {
    const url = `${getApiEndPoint(this.userService.getRole())}/agent`;
    return this.httpService.get(url);
  }

  public createAgent(payload: any) {
    const url = `${getApiEndPoint(this.userService.getRole())}/agent`;
    return this.httpService.post(url, payload);
  }

  public getAgent(agentId: number) {
    const url = `${getApiEndPoint(this.userService.getRole())}/agent/${agentId}`;
    return this.httpService.get(url);
  }

  public updateAgent(agentId: number, payload: any) {
    const url = `${getApiEndPoint(this.userService.getRole())}/agent/${agentId}`;
    return this.httpService.put(url, payload);
  }

  public deleteAgent(agentId: number) {
    const url = `${getApiEndPoint(this.userService.getRole())}/agent/${agentId}`;
    return this.httpService.delete(url);
  }

  public getUserList(agentId: number, payload = {}) {
    const url = `${getApiEndPoint(this.userService.getRole())}/agent/${agentId}/user`;
    return this.httpService.get(url, payload);
  }
}
