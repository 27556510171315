import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../services';
import { HTTP_STATUS } from '../../../../constants/http-status';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'x-api-token': this.authService.getApiToken(),
      },
    });

    return next.handle(request)
    .pipe(
      tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Write any code for handling all API responses here
          // throw new Error('Testing');
        }
        return event;
      }, error => {
        if (error instanceof HttpErrorResponse) {
          if ([HTTP_STATUS.UNAUTHORIZED].includes(error.status)) {
            this.authService.deleteUserInfo();
            this.authService.navigateToLogin();
            return;
          }
        }
      }),
    );
  }
}
