import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

import { ITEM_TAB_TYPE, MENU_ITEM_KEY, PLATFORM_IMAGE_MAPPER } from '../../../constants';
import { ActionMenu, ImageType, ItemType } from '../types';

export class ItemAdapter {
  public itemId: number;
  public platformService: {
    listing: string,
    crosslisting: string,
    relisting: string,
  };
  public brand: string;
  public title: string;
  public gender: string;
  public price: number;
  public quantity: number;
  public conditions: string;
  public sku: string;
  public cogs: number;
  public height: string;
  public width: string;
  public color: string;
  public size: string;
  public measurement: {
    chestSize: string,
    fullLength: string,
    waist: string,
    hips: string,
    sleeveLength: string,
    rise: string,
    inseam: string,
    legOpening: string,
    shoulderLength: string,
    height: string,
    width: string,
  };
  public shipping: {
    weightInLbs: string,
    weightInOz: string,
    boxHeigh: string,
    boxWidth: string,
    boxLength: string,
  };
  public keywords: string;
  public notes: string;
  public userConcern: string;
  public agentConcern: string;
  public status: string = ITEM_TAB_TYPE.NEW.label;
  public images = [];
  public itemImages = [];

  public appUser: any;

  public itemActionMenu: [ActionMenu];
  public listingName: string;
  public crosslistingName: string;
  public selected: boolean = false;
  public isAllDownloading: boolean = false;

  constructor(item: ItemType, appUser: any) {
    this.appUser = appUser;
    for (const key of Object.keys(item)) {
      this[key] = item[key];
    }

    this.setImages();
  }

  get truncateTitle() {
    let text = this.title || '';
    const length = 55;
    if (text.length > length) {
      text = text.substr(0, length);
      text += '...';
    }
    return text;
  }

  get listingPlatforms() {
    const listings = this.listingName.split(',');
    const listingArr = [];
    for (const name of listings) {
      if (!name) {
        return;
      }
      listingArr.push({
        checked: false,
        id: Math.random(),
        imageUrl: PLATFORM_IMAGE_MAPPER[name.toLowerCase()] || null,
        key: name.toLowerCase(),
        label: name,
      });
    }
    return listingArr;
  }

  get crosslistingPlatforms() {
    const crosslistings = this.crosslistingName.split(',');
    const crosslistingArr = [];
    for (const name of crosslistings) {
      if (!name) {
        return;
      }
      crosslistingArr.push({
        checked: false,
        id: Math.random(),
        imageUrl: PLATFORM_IMAGE_MAPPER[name.toLowerCase()] || null,
        key: name.toLowerCase(),
        label: name,
      });
    }
    return crosslistingArr;
  }

  get canUpdate(): boolean {
    if (this.appUser.isAdmin) {
      return false;
    }

    if (this.appUser.isAgent) {
      return [ITEM_TAB_TYPE.SUBMITTED.label, ITEM_TAB_TYPE.USER_CONCERN.label, ITEM_TAB_TYPE.DRAFTED.label].includes(this.status);
    }

    return [ITEM_TAB_TYPE.NEW.label, ITEM_TAB_TYPE.AGENT_CONCERN.label].includes(this.status);
  }

  get canDelete(): boolean {
    if (!this.appUser.isUser) {
      return false;
    }
    return [ITEM_TAB_TYPE.NEW.label].includes(this.status);
  }

  get primaryImage(): ImageType | {} {
    if (!this.itemImages?.length) {
      return {};
    }
    return this.itemImages.find(image => image.isDefault === true) || this.itemImages[0];
  }

  get markSubmitted(): boolean {
    if (this.appUser.isUser) {
      return this.itemId && [ITEM_TAB_TYPE.NEW.label, ITEM_TAB_TYPE.AGENT_CONCERN.label].includes(this.status);
    }
    return false;
  }

  get markDrafted(): boolean {
    if (this.appUser.isAgent) {
      return [ITEM_TAB_TYPE.SUBMITTED.label, ITEM_TAB_TYPE.USER_CONCERN.label].includes(this.status);
    }
    return false;
  }

  get canAgentAddConcern(): boolean {
    if (this.appUser.isAgent) {
      return [ITEM_TAB_TYPE.SUBMITTED.label,  ITEM_TAB_TYPE.AGENT_CONCERN.label].includes(this.status);
    }
    return false;
  }

  get canUserAddConcern(): boolean {
    if (this.appUser.isUser) {
      return [ITEM_TAB_TYPE.DRAFTED.label, ITEM_TAB_TYPE.USER_CONCERN.label].includes(this.status);
    }
    return false;
  }

  get markListed(): boolean {
    if (this.appUser.isAdmin) {
      return false;
    }
    return [ITEM_TAB_TYPE.DRAFTED.label].includes(this.status);
  }

  get concern(): string {
    if (this.status === ITEM_TAB_TYPE.USER_CONCERN.label) {
      return this.userConcern;
    }

    if (this.status === ITEM_TAB_TYPE.AGENT_CONCERN.label) {
      return this.agentConcern;
    }
    return '';
  }

  private setImages() {
    if (!this.images) {
      return;
    }
    for (const image of this.images) {
      this.addImage({
        imageId: image.imageId,
        fileName: image.fileName,
        fileUrl: image.fileUrl,
        isDefault: image.isDefault,
        base64: '',
      });
    }
  }

  public createItemMenu(): void {
    const list: [ActionMenu] = [
      { title: `${this.canUpdate ? 'Edit' : 'View'}`, icon: 'fa fa-eye', key: MENU_ITEM_KEY.VIEW_EDIT },
    ];

    if (this.canDelete) {
      list.push({ title: 'Delete', icon: 'fa fa-trash', key: MENU_ITEM_KEY.DELETE });
    }
    if (this.canAgentAddConcern) {
      list.push({ title: 'Add Concern', icon: 'fa fa-flag', key: MENU_ITEM_KEY.AGENT_CONCERN });
    }
    if (this.canUserAddConcern) {
      list.push({ title: 'Add Concern', icon: 'fa fa-flag', key: MENU_ITEM_KEY.USER_CONCERN });
    }
    this.itemActionMenu = list;
  }

  public setListingName(listingName: string): void {
    this.listingName = listingName;
  }

  public setCrosslistingName(crosslistingName: string): void {
    this.crosslistingName = crosslistingName;
  }

  public addImage({base64, imageId, fileName, fileUrl, isDefault}) {
    if (isDefault === undefined) {
      isDefault = this.itemImages.length < 1;
    }
    const image = new ItemImageAdapter({
      base64,
      imageId,
      fileName,
      fileUrl,
      isDefault,
      canUpdate: this.canUpdate,
      canDelete: this.canUpdate,
    });
    this.itemImages.push(image);
    return image;
  }

  public removeImage(imageId): void {
    const index = this.itemImages.findIndex(uploadedImage => uploadedImage.imageId === imageId);
    this.itemImages.splice(index, 1);
  }

  public markPrimaryImage(imageId): void {
    this.itemImages.map(image => image.setDefault(image.imageId === imageId));
  }

  public setBase64(imageId, base64): void {
    const image = this.itemImages.find(uploadedImage => uploadedImage.imageId === imageId);
    image.setBase64(base64);
  }

  public resetImages(): void {
    this.itemImages = [];
  }

  public async downloadAllImages() {
    const zip = new JSZip();
    let count = 0;
    const zipFilename = `${this.itemId}.zip`;
    const totalFiles = this.itemImages.length;

    this.isAllDownloading = true;
    const $this = this;
    this.itemImages.forEach(function(file) {
      JSZipUtils.getBinaryContent(file.fileUrl, function (err, data) {
        count++;
        if (err) {
          Image.downloadImageUsingHtml(file.fileUrl, file.fileName);

          $this.isAllDownloading = false;
          throw err; // or handle the error
        }
        zip.file(file.fileName, data, { binary: true });

        if (count === totalFiles) {
          zip.generateAsync({ type: 'blob' })
            .then(function(content) {
              saveAs(content, zipFilename);
            });
        }
        $this.isAllDownloading = false;
      });
    });
  }

}


export class ItemImageAdapter {
  public imageId: number;
  public fileName: string;
  public fileUrl: string;
  public isDefault: boolean = false;
  public canDelete: boolean = false;
  public canUpdate: boolean = false;
  public markPrimaryEvent: any;
  public disableDownload: boolean = false;

  public base64: string;

  constructor(itemImage: any) {
    for (const key of Object.keys(itemImage)) {
      this[key] = itemImage[key];
    }
  }

  get customBtnLabel(): string {
    return this.canUpdate && !this.isDefault ? 'Mark Primary' : '';
  }

  get previewFile(): string {
    return this.fileUrl || this.base64;
  }

  get isUploading(): boolean {
    return !this.fileUrl;
  }

  public setDefault(_isDefault: boolean) {
    this.isDefault = _isDefault;
  }

  public setFileUrl(fileUrl: string) {
    this.fileUrl = fileUrl;
  }

  public async download() {
    this.disableDownload = true;
    fetch(this.fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        Image.downloadImageUsingHtml(blobUrl, this.fileName);
        this.disableDownload = false;
      }).catch(error => {
        Image.downloadImageUsingHtml(this.fileUrl, this.fileName);
      });
  }

  public static downloadImageUsingHtml(url: string, fileName: string) {
    const a = document.createElement('a');
    a.download = fileName.replace(/^.*[\\\/]/, '');
    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}

class Image {
  public static downloadImageUsingHtml(url: string, fileName: string) {
    const a = document.createElement('a');
    a.download = fileName.replace(/^.*[\\\/]/, '');
    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}
