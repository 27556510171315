import { Injectable } from '@angular/core';
import { getApiEndPoint, ROLES } from '../../../../constants';
import { HttpService } from '../http/http.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ListingService {

  constructor(
    private httpService: HttpService,
    private userService: UserService) { }

  public getFormFields() {
    // const url = 'data/staticData.json';
    const url = `${getApiEndPoint(this.userService.getRole())}/services/staticForms`;
    return this.httpService.get(url);
  }

  public saveListingInfo(data: any) {
    const url = `${getApiEndPoint(ROLES.USER)}`;
    return this.httpService.put(url, data);
  }

  public getUserServiceCharge(data: any, userId?: number) {
    const url = [];
    url.push(getApiEndPoint(this.userService.getRole()));
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`serviceCharge`);
    return this.httpService.get(url.join('/'), data);
  }

  public createServiceCharge(data, userId) {
    const url = [];
    url.push(getApiEndPoint(this.userService.getRole()));
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`serviceCharge`);
    return this.httpService.post(url.join('/'), data);
  }

  public updateServiceCharge(serviceChargeID, data, userId) {
    const url = [];
    url.push(getApiEndPoint(this.userService.getRole()));
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`serviceCharge/${serviceChargeID}`);
    return this.httpService.put(url.join('/'), data);
  }
}
