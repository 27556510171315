import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  public showSidebar: boolean = false;
  private sidebarSubject = new Subject<any>();
  public sidebarChange$ = this.sidebarSubject.asObservable();

  constructor() {}

  public toggleSidebar(flag: boolean): void {
    this.showSidebar = flag;
    this.sidebarSubject.next(flag);
  }

  public getSidebarFlag(): boolean {
    return this.showSidebar;
  }


}
