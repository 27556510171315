const PROFILE_FORM = {
  controls: [
    {
      name: 'email',
      label: 'Email',
      value: '',
      type: 'email',
      readonly: false,
      validators: {},
    },
    {
      name: 'firstName',
      label: 'First Name',
      value: '',
      type: 'text',
      validators: {
        required:  true,
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      value: '',
      type: 'text',
      validators: {
        required:  true,
      },
    },
    {
      name: 'userName',
      label: 'Username',
      value: '',
      type: 'text',
      validators: {
        required:  true,
      },
    },
    {
      name: 'phone',
      label: 'Phone number',
      value: '',
      type: 'number',
      validators: {
        minlength: 10,
        maxlength: 10,
      },
    },
    {
      name: 'address',
      label: 'Address',
      value: '',
      type: 'textarea',
      validators: {},
    },
  ],
};

const RESET_PASSWORD_FORM = {
  controls: [
    {
      name: 'password',
      label: 'New Password',
      value: '',
      type: 'password',
      validators: {
        required:  true,
      },
    },
    {
      name: 'confirm_password',
      label: 'Confirm Password',
      value: '',
      type: 'password',
      validators: {
        required:  true,
      },
    },
  ],
};

const USER_CRUD_FORM = {
  controls: [
    {
      name: 'email',
      label: 'Email',
      value: '',
      type: 'email',
      validators: {
        required:  true,
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      value: '',
      type: 'text',
      validators: {
        required:  true,
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      value: '',
      type: 'text',
      validators: {
        required:  true,
      },
    },
    {
      name: 'userName',
      label: 'Username',
      value: '',
      type: 'text',
      validators: {
        required:  true,
      },
    },
    {
      name: 'phone',
      label: 'Phone number',
      value: '',
      type: 'text',
      validators: {},
    },
    {
      name: 'address',
      label: 'Address',
      value: '',
      type: 'textarea',
      validators: {},
    },
  ],
};

export { PROFILE_FORM, RESET_PASSWORD_FORM, USER_CRUD_FORM };
