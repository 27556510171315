
export class ServiceChargeAdapter {
  public walletTransactionId: number;
  public status: string;
  public amount: number = 0;
  public mode: string;

  public name: string;
  public description: string = '';
  public linkFrom: string;
  public linkId: string;

  public createdDate: string;
  public modifiedDate: string;

  public requestedBy: string;
  public requestorId: number;

  constructor(transactionObj: any) {
    for (const key of Object.keys(transactionObj)) {
      this[key] = transactionObj[key];
    }
  }
}
