import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private cookieService: CookieService) { }

  public setInCookie(key: string, value: any, expiresInMinutes?: number, path: string = '/') {
    let expireDate = null;
    if (expiresInMinutes) {
      expireDate = new Date();
      expireDate.setMinutes(expireDate.getMinutes() + expiresInMinutes);
    }

    if (value) {
      this.cookieService.set(key, value, expireDate, path);
    }
  }

  public getFromCookie(key: string) {
    return this.cookieService.get(key);
  }

  public deleteCookie(key: string, path: string = '/') {
    this.cookieService.delete(key, path);
  }

  public clearAllCookie() {
    this.cookieService.deleteAll();
  }

  public setInLocalStorage(key, value) {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    window.localStorage.setItem(key, value);
  }

  public getFromLocalStorage(key: string) {
    return window.localStorage.getItem(key);
  }

  public deleteFromLocalStorage(key: string) {
    window.localStorage.removeItem(key);
  }

  public clearAllLocalStorage() {
    window.localStorage.clear();
  }
}
