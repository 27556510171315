import { Injectable } from '@angular/core';
export interface AlertConfig {
  message: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlertBoxService {

  public ALERT_BOX_TYPE = {
    PRIMARY: 'primary',
    SECONDARY: 'basic',
    SUCCESS: 'success',
    ERROR: 'danger',
    WARNING: 'warning',
    INFO: 'info',
    CONTROL: 'control',
  };

  constructor() {
  }

  public getDefaultConfig(): AlertConfig {
    return {
      message: '',
      type: '',
    };
  }

  public setMessage(message: string = '', type: string = this.ALERT_BOX_TYPE.ERROR): AlertConfig {
    return {
      message,
      type,
    };
  }
}
