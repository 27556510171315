enum DEFAULT_ROUTES {
  AUTH = 'auth',
  USER_APPLICATION = 'user',
  AGENT_APPLICATION = 'agent',
  ADMIN_APPLICATION = 'admin',
}

const USER_APP_ROUTES = {
  LIST_ITEM: { NAME: 'item-list', NAVIGATION: [`/${DEFAULT_ROUTES.USER_APPLICATION}/item-list`], PAGE_TITLE: 'Items' },
  CREATE_ITEM: { NAME: 'item', NAVIGATION: [`/${DEFAULT_ROUTES.USER_APPLICATION}/item`], PAGE_TITLE: 'Item', HIDE_ON_NAVBAR: true },
  ITEM_VIEW_EDIT: { NAME: 'item/:itemId', NAVIGATION: [`/${DEFAULT_ROUTES.USER_APPLICATION}/item/:itemId`], PAGE_TITLE: 'Item', HIDE_ON_NAVBAR: true },

  SERVICES: { NAME: 'services', NAVIGATION: [`/${DEFAULT_ROUTES.USER_APPLICATION}/services`], PAGE_TITLE: 'Services' },

  PROFILE: { NAME: 'profile', NAVIGATION: [`/${DEFAULT_ROUTES.USER_APPLICATION}/profile`], PAGE_TITLE: 'Profile' },
};

const AGENT_APP_ROUTES = {
  PROFILE: { NAME: 'profile', NAVIGATION: [`/${DEFAULT_ROUTES.AGENT_APPLICATION}/profile`], PAGE_TITLE: 'Profile' },
};

const ADMIN_APP_ROUTES = {
  USER_LIST: { NAME: 'user-list', NAVIGATION: [`/${DEFAULT_ROUTES.ADMIN_APPLICATION}/user-list`], PAGE_TITLE: 'Users' },
  USER_CREATE: { NAME: 'user', NAVIGATION: [`/${DEFAULT_ROUTES.ADMIN_APPLICATION}/user`], PAGE_TITLE: 'User', HIDE_ON_NAVBAR: true },
  USER_ITEM: { NAME: 'user/:userId/items', NAVIGATION: [`/${DEFAULT_ROUTES.ADMIN_APPLICATION}/user/:userId/items`], PAGE_TITLE: 'Items', HIDE_ON_NAVBAR: true },
  USER_ITEM_VIEW: { NAME: 'user/:userId/items/:itemId', NAVIGATION: [`/${DEFAULT_ROUTES.ADMIN_APPLICATION}/user/:userId/items/:itemId`], PAGE_TITLE: 'Item', HIDE_ON_NAVBAR: true },
  USER_SERVICE: { NAME: 'user/:userId/services', NAVIGATION: [`/${DEFAULT_ROUTES.ADMIN_APPLICATION}/user/:userId/services`], PAGE_TITLE: 'Services', HIDE_ON_NAVBAR: true },

  AGENT_LIST: { NAME: 'agent-list', NAVIGATION: [`/${DEFAULT_ROUTES.ADMIN_APPLICATION}/agent-list`], PAGE_TITLE: 'Agents' },
  AGENT_CREATE: { NAME: 'agent', NAVIGATION: [`/${DEFAULT_ROUTES.ADMIN_APPLICATION}/agent`], PAGE_TITLE: 'Agent', HIDE_ON_NAVBAR: true },
  AGENT_VIEW_EDIT: { NAME: 'agent/:agentId', NAVIGATION: [`/${DEFAULT_ROUTES.ADMIN_APPLICATION}/agent/:agentId`], PAGE_TITLE: 'Agent', HIDE_ON_NAVBAR: true },
  AGENT_CLIENT: { NAME: 'agent/:agentId/clients', NAVIGATION: [`/${DEFAULT_ROUTES.ADMIN_APPLICATION}/agent/:agentId/clients`], PAGE_TITLE: 'Agent Clients', HIDE_ON_NAVBAR: true },

  PROFILE: { NAME: 'profile', NAVIGATION: [`/${DEFAULT_ROUTES.ADMIN_APPLICATION}/profile`], PAGE_TITLE: 'Profile' },

};

export {
  DEFAULT_ROUTES,
  USER_APP_ROUTES,
  AGENT_APP_ROUTES,
  ADMIN_APP_ROUTES,
};
