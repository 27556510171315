
export class UserPlatformServiceAdapter {
  public userPlatformServiceId: number;
  public platformKey: string;
  public platformName: string;
  public serviceType: string;
  public services: any;

  public userId: number;

  constructor(platformObj: any) {
    for (const key of Object.keys(platformObj)) {
      this[key] = platformObj[key];
    }
  }
}
