// import * as moment from 'moment';
import { ITEM_TAB_LIST, ROLES, USER_STATUS } from '../../../constants';
import { ItemType } from '../types';

class AppUser {
  public userId: number;
  public email: string;
  public firstName: string;
  public lastName: string;
  public userName: string;
  public address: string;
  public phone: string;
  public userType: string;
  public referralCode: string;
  public type: string;

  constructor(object, userType: string) {
    this.userId = object.userId;
    this.email = object.email;
    this.firstName = object.firstName;
    this.lastName = object.lastName;
    this.userName = object.userName;
    this.address = object.address;
    this.phone = object.phone;
    this.userType = userType;
    this.referralCode = object.referralCode;
    this.type = object.type;
  }

  get fullName(): String {
    const arr = [];
    arr.push(this.firstName);
    arr.push(this.lastName);
    return arr.join(' ');
  }

  get isUser(): boolean {
    return false;
  }

  get isAdmin(): boolean {
    return false;
  }

  get isAgent(): boolean {
    return false;
  }

  get isSecondaryAdmin(): boolean {
    return false;
  }
}

export class UserAdapter extends AppUser {
  public adminId: number;
  public agentId: number;
  public amount: number;
  public status: string;
  public instructions: string;
  public isVerified: boolean = false;
  public purchaseExpireDate: string = '';

  get isUser(): boolean {
    return true;
  }

  get isSubscribed(): boolean {
    // const today = moment();
    const subscribed = this.status === USER_STATUS.SUBSCRIBE;
    return subscribed; // && moment(this.purchaseExpireDate).isAfter(today);
  }

  constructor(userObject) {
    super(userObject, ROLES.USER);
    this.agentId = userObject.agentId;
    this.amount = userObject.amount - 0;
    this.status = userObject.status;
    this.instructions = userObject.instructions;
    this.isVerified = userObject.isVerified;
    this.purchaseExpireDate = userObject.purchaseExpireDate;
  }
}

export class AgentAdapter extends AppUser {
  public agentId: number;

  get isAgent(): boolean {
    return true;
  }

  constructor(agentObject) {
    super(agentObject, ROLES.AGENT);
    this.agentId = agentObject.agentId;
  }
}

export class AdminAdapter extends AppUser {

  get isAdmin(): boolean {
    return true;
  }

  get isSecondaryAdmin(): boolean {
    return this.type === 'Secondary';
  }

  constructor(adminObject) {
    super(adminObject, ROLES.ADMIN);
  }
}


export class UserWithItemAdapter extends UserAdapter {
  public serviceRoute = '';
  public itemRoute = '';
  public serviceChargeRoute = '';
  public walletRoute = '';
  public items: [] = [];
  public agent = {};
  public createdDate: string;
  public modifiedDate: string;
  public count = {};

  setServiceRoute(url): void {
    if (!url) {
      return;
    }
    this.serviceRoute = url.replace(':userId', this.userId);
  }

  setItemRoute(url): void {
    if (!url) {
      return;
    }
    this.itemRoute = url.replace(':userId', this.userId);
  }

  setServiceChargeRoute(url): void {
    if (!url) {
      return;
    }
    this.serviceChargeRoute = url.replace(':userId', this.userId);
  }

  setWalletRoute(url): void {
    if (!url) {
      return;
    }
    this.walletRoute = url.replace(':userId', this.userId);
  }

  setAgent(agentList): void {
    if (this.agentId) {
      this.agent = agentList.filter(agent => agent.key === this.agentId);
    }
  }

  private setItemTypeCount(): void {
    for (const itemType of ITEM_TAB_LIST) {
      this.count[itemType.title] = this.items.filter((item: ItemType) => item.status === itemType.title).length || 0;
    }
  }

  constructor(userObject) {
    super(userObject);
    this.items = userObject.item || [];
    this.createdDate = userObject.createdDate;
    this.modifiedDate = userObject.modifiedDate;
    this.setItemTypeCount();
  }
}
