import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DEFAULT_ROUTES } from './constants';
import { AuthGuardService } from './modules/core/gaurd';

export const routes: Routes = [
  {
    path: DEFAULT_ROUTES.AUTH,
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: DEFAULT_ROUTES.USER_APPLICATION,
    canLoad: [AuthGuardService],
    loadChildren: () => import('./modules/user-application/user-application.module').then(m => m.UserApplicationModule),
  },
  {
    path: DEFAULT_ROUTES.ADMIN_APPLICATION,
    canLoad: [AuthGuardService],
    loadChildren: () => import('./modules/admin-application/admin-application.module').then(m => m.AdminApplicationModule),
  },
  {
    path: DEFAULT_ROUTES.AGENT_APPLICATION,
    canLoad: [AuthGuardService],
    loadChildren: () => import('./modules/agent-application/agent-application.module').then(m => m.AgentApplicationModule),
  },
  { path: '', redirectTo: DEFAULT_ROUTES.AUTH, pathMatch: 'full' },
  { path: '**', redirectTo: DEFAULT_ROUTES.AUTH },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
