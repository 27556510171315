import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { getApiEndPoint, KEYS, ROLES } from '../../../../constants';
import { AdminAdapter, AgentAdapter, UserAdapter, UserPlatformServiceAdapter } from '../../../shared/adapters';
import { HttpService } from '../http/http.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private role: string = null;
  private userInfo: any = {};

  constructor(
    private httpService: HttpService,
    private storageService: StorageService) { }

  public getRole(): string {
    if (!this.role) {
      this.role = this.storageService.getFromLocalStorage(KEYS.ROLE) || '';
    }

    return this.role;
  }

  public setRole(role: string): void {
    if (role) {
      this.role = role;
      this.storageService.setInLocalStorage(KEYS.ROLE, role);
    }
  }

  public getUserInfo() {
    let user = null;
    if (!Object.keys(this.userInfo).length) {
      const localStorageInfo = this.storageService.getFromLocalStorage(KEYS.USER_INFO) || '';
      user = localStorageInfo ? JSON.parse(localStorageInfo) : null;
    }
    if (!user) {
      user = this.userInfo;
    }
    switch (user?.userType) {
      case ROLES.USER:
        user = new UserAdapter(user);
        break;
      case ROLES.AGENT:
        user = new AgentAdapter(user);
        break;
      case ROLES.ADMIN:
        user = new AdminAdapter(user);
        break;
      default:
        user = null;
    }
    return user;
  }

  public setUserInfo(userInfo: any, role?: string): void {
    if (Object.keys(userInfo).length) {
      this.setRole(userInfo.userType);
      this.userInfo = userInfo;
      this.storageService.setInLocalStorage(KEYS.USER_INFO, this.userInfo);
    }
  }

  public updateUserDetail(data: any, userId?: number) {
    const url = [`${getApiEndPoint(this.getRole())}`];
    if (userId) {
      url.push(`user/${userId}`);
    }
    return this.httpService.put(url.join('/'), data);
  }

  public getUserDetail(userId?: number) {
    const url = [`${getApiEndPoint(this.getRole())}`];
    if (userId) {
      url.push(`user/${userId}`);
    }
    return this.httpService.get(url.join('/'));
  }

  public getUserList(data: any) {
    const url = `${getApiEndPoint(this.getRole())}/user`;
    return this.httpService.get(url, data);
  }

  public getInactiveUsers() {
    const url = `${getApiEndPoint(this.getRole())}/user/inactive`;
    return this.httpService.get(url);
  }

  public deleteUser(userId?: number) {
    const url = `${getApiEndPoint(this.getRole())}/user/${userId}`;
    return this.httpService.delete(url);
  }

  public getPlatformService(userId?: number, groupBytype: boolean = true) {
    const url = [`${getApiEndPoint(this.getRole())}`];
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push('userPlatformService');
    return this.httpService.get(url.join('/'))
      .pipe(
        map(res => {
          return groupBytype ? this.groupByServiceType(res) : res;
        }),
      );
  }

  public deletePlatformService(userPlatformServiceId?: number) {
    const url = `${getApiEndPoint(this.getRole())}/userPlatformService/${userPlatformServiceId}`;
    return this.httpService.delete(url);
  }

  public createUserPlatform(data, userId?: number) {
    const url = [`${getApiEndPoint(this.getRole())}`];
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push('userPlatformService');
    return this.httpService.post(url.join('/'), data);
  }

  public updateUserPlatform(userServicePlatformId, data, userId?: number) {
    const url = [`${getApiEndPoint(this.getRole())}`];
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`userPlatformService/${userServicePlatformId}`);
    return this.httpService.put(url.join('/'), data);
  }

  public updateStatus(userId: number, data: any) {
    const url = `${getApiEndPoint(this.getRole())}/user/${userId}/status`;
    return this.httpService.put(url, data);
  }

  public unsubscribe(userId?: number) {
    const url = [`${getApiEndPoint(this.getRole())}`];
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`unSubscribe`);
    return this.httpService.put(url.join('/'), {});
  }

  public groupByServiceType(array = []) {
    const result = {};
    for (const data of array) {
      if (!result[data.serviceType]) {
        result[data.serviceType] = [];
      }
      result[data.serviceType].push(new UserPlatformServiceAdapter(data));
    }
    return result;
  }
}
