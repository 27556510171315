const GENERIC_MESSAGES = {
  SIGNUP_SUCCESS: 'Your account is created. Please go to login',
  ADD_ITEM_SUCCESS: 'Your item is added',
  UPDATE_ITEM_SUCCESS: 'Your item is updated',
  DELETE_ITEM_SUCCESS: 'Your item is deleted',
  DELETE_ITEM_CONFIRMATION: 'Do you want to delete',
  DELETE_IMAGE_SUCCESS: 'Image deleted Successfully',
  BULK_UPDATE_ITEMS: 'Do you want to bulk update selected items',
  BULK_DELETE_ITEMS: 'Do you want to bulk delete select selected items',
  ERROR_MESSAGE: 'Something went wrong',
  AGENT_CREATE_SUCCESS: 'Agent created successfully',
  AGENT_UPDATE_SUCCESS: 'Agent info updated successfully',
  DELETE_AGENT_CONFIRMATION: 'Do you want to delete',
  INVALID_USER: 'Invalid User',
  INVALID_AGENT: 'Invalid Agent',
  PASSWORD_NOT_MATCH: 'Password not match',
  CONCERN_ADDED: 'Concern Added',
  INSTRUCTION_ADDED: 'Instruction Updated',
  SERVICE_ADD_SUCCESSFULL: 'Service added successfully',
  SERVICE_UPDATED_SUCCESSFULL: 'Service updated successfully',
  WALLET_ADD_MONEY_SUCCESS: 'Your transaction will be updated within 5-10 min. Please refresh to check it.',
  PLATFORM_SERVICE_REQUIRED: 'Atleast one platform need to be selectd',
  COMMON_PLATFORM_SERVICE: 'You can\'t choose the same platform for Listing and Crosslisting',
  LOW_BALANCE: 'Your wallet balance is running low.',
};

export {
  GENERIC_MESSAGES,
};
