import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { AuthService } from '../../services';
@Injectable()
export class AuthGuardService implements CanLoad {

  constructor(
    public authService: AuthService,
    public router: Router) {}

    canLoad(): boolean {
    const token = this.authService.getApiToken();
    if (!(token)) {
      this.authService.deleteUserInfo();
      this.authService.navigateToLogin();
      return false;
    }
    return true;
  }
}
