import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public ACTION = {
    ADD: 'add',
    CLEAN: 'clean',
  };

  private breadcrumb = [];

  private breadcrumbSubject = new Subject<any>();
  public breadcrumbChange$ = this.breadcrumbSubject.asObservable();

  public parseRoute(route, extraParams?) {
    for (const property in extraParams)
    route.NAVIGATION = route.NAVIGATION.replace(`:${property}`, extraParams[property]);
    return route;
  }

  public add(displayName: string, routeUrl: string, extraParams?: any) {
    if (routeUrl) {
      for (const property in extraParams) {
        if (!extraParams[property]) {
          continue;
        }
        routeUrl = routeUrl.replace(`:${property}`, extraParams[property]);
      }
    }
    this.breadcrumb.push({
      title: displayName,
      routeUrl: routeUrl,
    });
  }

  public clean() {
    this.breadcrumb = [];
  }

  public getList() {
    return this.breadcrumb;
  }
}
