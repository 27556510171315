import { API_V1_END_POINT, ROLES } from './variables';

const getLabelFromKey = function (key: string) {
  const array = key.split('-');
  key = array.join(' ');
  return key.charAt(0).toUpperCase() + key.slice(1);
};

const getKeyFromLabel = function (value: string) {
  const array = value.split(' ');
  value = array.join('-');
  return value.toLowerCase();
};

const getApiEndPoint = function (roleType?: string) {
  let url = API_V1_END_POINT;
  switch (roleType) {
    case ROLES.AGENT:
      url += `/${ROLES.AGENT}`;
      break;
    case ROLES.ADMIN:
      url += `/${ROLES.ADMIN}`;
      break;
    case ROLES.USER:
    default:
      url += `/${ROLES.USER}`;
      break;
  }
  return url;
};

const isArray = function (item: any) {
  return Array.isArray(item);
};

const isEmptyObject = function (obj: object) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export {
  getApiEndPoint,
  getLabelFromKey,
  getKeyFromLabel,
  isArray,
  isEmptyObject,
};
