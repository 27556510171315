import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '..';
import { getApiEndPoint } from '../../../../constants';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ItemService {

  constructor(
    private httpService: HttpService,
    private userService: UserService) { }

  public getItemList(params?: any, userId?: any) {
    const url = [];
    url.push(getApiEndPoint(this.userService.getRole()));
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`item`);
    return this.httpService.get(url.join('/'), params);
  }

  public getItemCount(userId?: any) {
    const url = [];
    url.push(getApiEndPoint(this.userService.getRole()));
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`item/count`);
    return this.httpService.get(url.join('/'));
  }

  public createItem(payload) {
    const url = `${getApiEndPoint()}/item`;
    return this.httpService.post(url, payload);
  }

  public getItem(itemId: number, userId?: number) {
    const url = [];
    url.push(getApiEndPoint(this.userService.getRole()));
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`item/${itemId}`);
    return this.httpService.get(url.join('/'));
  }

  public updateItem(itemId: number, payload, userId?: number) {
    const url = [];
    url.push(getApiEndPoint(this.userService.getRole()));
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`item/${itemId}`);
    return this.httpService.put(url.join('/'), payload);
  }

  public deleteItem(itemId: number, userId?: number) {
    const url = [];
    url.push(getApiEndPoint(this.userService.getRole()));
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`item/${itemId}`);
    return this.httpService.delete(url.join('/'));
  }

  // public uploadImage(itemId: number, image: any) {
  //   const url = `${getApiEndPoint(this.userService.getRole())}/item/${itemId}/image`;
  //   const payload = new FormData();
  //   payload.append('image', image);
  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type', 'multipart/formData');

  //   return this.httpService.post(url, payload, { observe: 'response', headers });
  // }

  // public deleteImage(itemId: number, imageId: number, fileName: string) {
  //   const url = `${getApiEndPoint(this.userService.getRole())}/item/${itemId}/image/${imageId}/${fileName}`;
  //   return this.httpService.delete(url);
  // }

  // public markPrimaryImage(itemId: number, imageId: number, userId?: number) {
  //   const url = `${getApiEndPoint(this.userService.getRole())}/item/${itemId}/image/${imageId}/setPrimary`;
  //   return this.httpService.put(url, {});
  // }

  public getImagePresignedUrl(fileName: string, fileType: string, userId?: number) {
    const url = [];
    url.push(getApiEndPoint(this.userService.getRole()));
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`item/image/presignedUrl`);
    return this.httpService.post(url.join('/'), {fileName, fileType});
  }

  public uploadImageUsingPresigned(url: string, file: any) {
    return this.httpService.put(url, file);
  }

  public bulkUpdate(itemIdArray: [], data: any, userId?: number) {
    const url = [];
    url.push(getApiEndPoint(this.userService.getRole()));
    if (userId) {
      url.push(`user/${userId}`);
    }
    url.push(`item/bulkUpdate`);
    return this.httpService.put(url.join('/'), {itemIdArray, ...data});
  }
}
