import { Component, OnInit } from '@angular/core';
import { SharedUtilsService } from './modules/core/services';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(private sharedUtilsService: SharedUtilsService) {
  }

  ngOnInit(): void {
    this.sharedUtilsService.setPageTitle();
    const afterLoadCb = window['afterAngularAppLoad'];
    if (afterLoadCb) {
      afterLoadCb();
    }
  }
}
