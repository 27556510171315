import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { isArray } from '../../../../constants';

@Injectable({
  providedIn: 'root',
})
export class HttpService {

  constructor(private http: HttpClient) { }

  public get(url: string, data?: any): Observable<any> {
    const paramArr = [];
    for (const property in data) {
      if (data[property]) {
        const value = data[property];
        if (isArray(value) && !value.length) {
          continue;
        }
        paramArr.push(`${property}=${value}`);
      }
    }
    if (paramArr.length) {
      url += `?${paramArr.join('&')}`;
    }
    return this.http.get(url)
      .pipe(catchError(this.errorHandler));
  }

  public delete(url: string, data: any = {}): Observable<any> {
    return this.http.delete(url, data)
      .pipe(catchError(this.errorHandler));
  }

  public post(url: string, data: any, headers?: any): Observable<any> {
    return this.http.post(url, data, headers)
      .pipe(catchError(this.errorHandler));
  }

  public put(url: string, data: any): Observable<any> {
    return this.http.put(url, data)
      .pipe(catchError(this.errorHandler));
  }

  private errorHandler(error: HttpErrorResponse) {
    return throwError(error.error);
  }
}
