import { Injectable } from '@angular/core';
// import { NbGlobalPhysicalPosition, NbGlobalPosition, NbIconConfig, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private duration = 5000;
  private NOTIFICATION_TYPE = {
    BASIC: 'basic',
    PRIMARY: 'primary',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'danger',
    CONTROL: 'control',
  };

  public positions = {
    TOP_RIGHT: 'top-right',
  };

  // constructor(private toastrService: NbToastrService) { }

  // showSuccess(message: string, title: string = 'Success', position = this.positions.TOP_RIGHT, icon: string = 'checkmark-circle-2') {
  //   const iconConfig: NbIconConfig = { icon, pack: 'eva' };
  //   this.toastrService.show(message, title, { duration: this.duration, status: this.NOTIFICATION_TYPE.SUCCESS, icon: iconConfig, position });
  // }

  // showError(message: string, title: string = 'Error', position: NbGlobalPosition = this.positions.TOP_RIGHT, icon: string = 'alert-triangle') {
  //   const iconConfig: NbIconConfig = { icon, pack: 'eva' };
  //   this.toastrService.show(message, title, { duration: this.duration, status: this.NOTIFICATION_TYPE.ERROR, icon: iconConfig, position });
  // }

  // showInfo(message: string, title: string = 'Info', position: NbGlobalPosition = this.positions.TOP_RIGHT, icon: string = 'info') {
  //   const iconConfig: NbIconConfig = { icon, pack: 'eva' };
  //   this.toastrService.show(message, title, { duration: this.duration, status: this.NOTIFICATION_TYPE.INFO, icon: iconConfig, position });
  // }

  // showWarning(message: string, title: string = 'Warning', position: NbGlobalPosition = this.positions.TOP_RIGHT, icon: string = 'alert-circle') {
  //   const iconConfig: NbIconConfig = { icon, pack: 'eva' };
  //   this.toastrService.show(message, title, { duration: this.duration, status: this.NOTIFICATION_TYPE.WARNING, icon: iconConfig, position });
  // }
  showSuccess(message: string, title: string = 'Success', position = this.positions.TOP_RIGHT, icon: string = 'checkmark-circle-2') {
  }

  showError(message: string, title: string = 'Error', position = this.positions.TOP_RIGHT, icon: string = 'alert-triangle') {
  }

  showInfo(message: string, title: string = 'Info', position = this.positions.TOP_RIGHT, icon: string = 'info') {
  }

  showWarning(message: string, title: string = 'Warning', position = this.positions.TOP_RIGHT, icon: string = 'alert-circle') {
  }

}
