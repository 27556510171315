import { Injectable } from '@angular/core';
import { DEFAULT_ROUTES, getApiEndPoint, KEYS } from '../../../../constants';
import { SharedUtilsService } from '../shared-utils/shared-utils.service';
import { StorageService } from '../storage/storage.service';
import { HttpService } from '../http/http.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpService: HttpService,
    private storageService: StorageService,
    private userService: UserService,
    private sharedUtilsService: SharedUtilsService,
    ) { }

  public createUser(userDetail: any) {
    const url = `${getApiEndPoint()}`;
    return this.httpService.post(url, userDetail);
  }

  public login(userDetail: any) {
    const url = `${getApiEndPoint(userDetail.userType)}/login`;
    return this.httpService.post(url, userDetail);
  }

  public logout(userDetail: any) {
    const url = `${getApiEndPoint(this.userService.getRole())}/logout`;
    return this.httpService.delete(url);
  }

  public forgotPassword(userInfo: any) {
    const url = `${getApiEndPoint(userInfo.userType)}/reset/password`;
    return this.httpService.put(url, userInfo);
  }

  public getApiToken() {
    return this.storageService.getFromCookie(KEYS.API_TOKEN);
  }

  public setApiToken(token: string) {
    this.storageService.setInCookie(KEYS.API_TOKEN, token);
  }

  public deleteUserInfo() {
    this.storageService.deleteCookie(KEYS.API_TOKEN);
    this.userService.setRole('');
    this.userService.setUserInfo({});
  }

  public navigateToLogin() {
    this.deleteUserInfo();
    const $this = this;
    setTimeout(function() {
      $this.sharedUtilsService.navigateTo(DEFAULT_ROUTES.AUTH);
    }, 700);
  }
}
